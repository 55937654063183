<template>
  <div id="callbackProvider">
    <div class="text-center px-5 py-5">
      <b-alert v-if="error" variant="danger" show>
        <div class="alert-body">
          <span> <strong>Error</strong> Something went wrong . </span>
          <br />
          <span v-if="errorDetails" v-text="errorDetails" />
        </div>
        <b-button
          class="mb-1"
          @click="$router.push({ name: 'login' })"
          variant="flat-danger"
        >
          Back from here
        </b-button>
      </b-alert>
      <div v-else>
        <b-spinner
          style="width: 3rem; height: 3rem"
          class="mb-2"
          label="Spinning"
        />
        <h1>Redirecting, please wait..</h1>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@/services/auth.service.js";
import { BButton, BAlert } from "bootstrap-vue";
export default {
  components: { BButton, BAlert },
  data: () => ({
    error: false,
    errorDetails: null,
  }),
  async mounted() {
    try {
      await Auth.loginWithCallback(this.$route.fullPath);
    } catch (e) {
      this.errorDetails = e.response?.data?.err;
      this.error = true;
    }
  },
};
</script>

<style>
</style>